<template>
  <div v-if="bmSm?.active" class="bmsm">
    <div class="bmsm-wrapper">
      <div class="header" v-text="bmSm.header" />
      <div v-if="currentThreshold" class="messaging">
        <div>
          {{ useTranslate('GLOBALS.youSave') + ' ' + currencySymbol + currentThreshold.savings_amount }}.
          <template v-if="thresPercent !== 100">
            Add
            <span class="next-thres-val">{{
              currencySymbol + (nextThreshold.threshold - cartStore.totalPrice).toFixed(2)
            }}</span>
            to <span class="next-thres-save">save {{ currencySymbol + nextThreshold.savings_amount }}</span
            >.
          </template>
        </div>
        <div class="flex-inline">
          <PriceTag3Line /> code:
          <span class="code" v-text="currentThreshold.coupon_code + (bmSm.countdown_date_time ? ' | ' : '')" />
          <div v-if="bmSm.countdown_date_time" class="flex-inline">
            <TimeLine /> ends: <span class="countdown" v-html="state.dateDisplay" />
          </div>
        </div>
      </div>
      <div v-else class="messaging">
        <span v-for="(threshold, thresInd) in bmSm.thresholds" :key="'msg' + threshold.savings_amount"
          >{{ thresInd === 0 ? 'S' : 's' }}pend {{ currencySymbol + threshold.threshold }} get
          {{ currencySymbol + threshold.savings_amount }} off{{ thresInd < numThresholds - 1 ? ', ' : '.' }}</span
        >
        <div v-if="bmSm.countdown_date_time" class="flex-inline">
          <TimeLine /> ends: <span class="countdown" v-html="state.dateDisplay" />
        </div>
      </div>
      <div class="progress-bar">
        <div class="bar-container flex">
          <div :class="['bar', { full: thresPercent === 100 }]" :style="`width: ${thresPercent}%`" />
        </div>
        <div class="nodes-wrapper flex-inline">
          <div
            v-for="threshold in bmSm.thresholds"
            :key="'thres' + threshold.coupon_code"
            :class="['thres-node flex-col cntr', { 'thres-met': isThresMet(threshold.threshold) }]"
          >
            <div class="threshold" v-text="currencySymbol + threshold.threshold" />
            <div class="discount" v-text="'Save ' + currencySymbol + threshold.savings_amount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRootStore } from '@/store';
import { useCartStore } from '@/store/cart';
import { useLocaleStore } from '@/store/locale';
import { formatNumDigits } from '~/util/string';
import { getFutureTime } from '~/util/date';

const rootStore = useRootStore();
const cartStore = useCartStore();
const { currencySymbol } = useLocaleStore();

const state = reactive({
  countdownInterval: 0 as any,
  hideCountdownInterval: 0 as any,
  countDownExpired: false,
  hideCountdownExpired: false,
  dateDisplay: '00 days 00 hours 00 min',
  hasMounted: false,
  currentThresIndex: -1,
});

const bmSm = computed<any>(() => rootStore.brandSettings?.buy_more_save_more);
const numThresholds = bmSm?.value?.thresholds?.length;
const maxThreshold = computed<number>(() => {
  return Math.max(...bmSm.value.thresholds.map((threshold: any) => threshold.threshold));
});

const thresPercent = computed<number>(() => {
  const percent = (cartStore.totalPrice / maxThreshold?.value) * 100;
  const incrementPercentSize = 100 / (numThresholds + 1);
  if (percent >= 100) {
    return 100;
  }
  // We have to do this because the top level threshold is not at the end of the progress bar
  const upperLimit = incrementPercentSize * numThresholds;

  const calcVal = (percent / 100) * upperLimit;
  return parseFloat(calcVal.toFixed(2));
});

const currentThreshold = computed<any>(() => {
  const thresholds = bmSm.value.thresholds;
  let maxThreshold = -Infinity;
  let maxThresholdCoupon = null;

  for (let i = 0; i < thresholds.length; i++) {
    const coupon = thresholds[i];

    if (coupon.threshold <= cartStore.totalPrice && coupon.threshold > maxThreshold) {
      maxThreshold = coupon.threshold;
      maxThresholdCoupon = coupon;
      state.currentThresIndex = i;
    }
  }

  return maxThresholdCoupon;
});

const nextThreshold = computed<any>(() => {
  if (state.currentThresIndex === -1) {
    return bmSm.value.thresholds[0];
  } else if (state.currentThresIndex === bmSm.value.thresholds.length - 1) {
    return null;
  } else {
    return bmSm.value.thresholds[state.currentThresIndex + 1];
  }
});

onMounted(() => {
  if (bmSm?.value?.countdown_date_time) {
    const countDownDate = new Date(bmSm?.value?.countdown_date_time).getTime();
    state.countdownInterval = setInterval(() => {
      countdownMovement(countDownDate);
    }, 1000);
    countdownMovement(countDownDate);
  }
  state.hasMounted = true;
});
onUnmounted(() => {
  clearInterval(state.countdownInterval);
  clearInterval(state.hideCountdownInterval);
});

// TODO - refactor here and topbanner as util functions
function countdownMovement(countDownDate: number) {
  const { days, hours, minutes, distance } = getFutureTime(countDownDate);

  // If the count down is finished, hide component
  if (distance < 0) {
    clearInterval(state.countdownInterval);
    state.countDownExpired = true;
  } else {
    state.dateDisplay = `<span class="number">${formatNumDigits(
      days,
      2
    )}</span> days <span class="number">${formatNumDigits(hours, 2)}</span> hrs  <span class="number">${formatNumDigits(
      minutes,
      2
    )}</span> min`;
  }
}
function isThresMet(thres: number) {
  return cartStore.totalPrice >= thres;
}
</script>

<style lang="scss">
.bmsm .messaging {
  svg {
    fill: $color-primary-500;
    margin-right: 4px;
  }
}
</style>

<style lang="scss" scoped>
.bmsm {
  background-color: $color-neutral-white;
  padding: 1.5rem 1rem;
  margin: 0 -1rem;
  line-height: 150%;
  @include local-mixins.desktop {
    padding: 1.5rem 1.5rem;
    margin: 0 -1.5rem;
  }
}
.bmsm-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid $color-neutral-cool-100;
}
.header {
  color: $color-neutral-cool-900;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.messaging {
  color: $color-neutral-black;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  .countdown {
    margin-left: 4px;
  }
  .number,
  .code {
    font-weight: 500;
  }
  .code {
    margin-left: 4px;
    margin-right: 4px;
  }
}
.next-thres-val {
  color: $color-danger-default;
  font-weight: 500;
}
.next-thres-save {
  font-weight: 500;
}
.bar-container {
  border-radius: 3px;
  background-color: #f5f7fa;
  width: 100%;
  height: 6px;
}
.bar {
  background-color: $color-primary-500;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  &.full {
    border-radius: 3px;
  }
}
.nodes-wrapper {
  position: relative;
  justify-content: space-evenly;
  .thres-node {
    color: $color-neutral-black;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 125%;
    margin-top: 10px;
    .discount {
      color: $color-neutral-cool-500;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: -10px;
      width: 14px;
      height: 14px;
      background-color: $color-neutral-cool-200;
      border-radius: 7px;
    }
    &.thres-met {
      &::before {
        content: ' ';
        @include local-mixins.backgroundImage(local-functions.svg-img-source('check-line.svg'), 14px, 14px);
        background-size: 12px;
        background-color: $color-primary-500;
      }
    }
  }
}
</style>
